<template>
  <div v-if="canView">
    <div :class="getClass()" class="agenda-title" :style="getStyle()" v-if="showHeader">
      <b-row>
        <b-col>
          <h2 style="display: inline-block">
            <span class="hide-here">{{ name }} - </span>Agenda
          </h2>
        </b-col>
        <b-col cols="2" class="no-print">
        </b-col>
        <b-col cols="3" class="text-right no-print">
          <span v-if="selectedItems.length">
            <a href @click.prevent="changePlaces" class="btn btn-secondary btn-sm">
              <i class="fa fa-location-dot"></i> Modifier le lieu
            </a>
          </span>
        </b-col>
      </b-row>
      </div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="agendaDates" v-show="!isLoading(loadingName)">
      <div v-if="agendaDates.length === 0" class="empty-text">
        Aucune date
      </div>
      <div v-show="agendaDates.length > 0">
        <div class="elements-counter">
          <counter-label :counter="agendaDates.length" label="date"></counter-label>
          <span v-if="hasAbsences">
            -
            <counter-label :counter="activeDates.length" label="active"></counter-label>
            -
            <counter-label :counter="absenceDates.length" label="absence"></counter-label>
          </span>
        </div>
        <x-table
          :columns="columns"
          :items="items"
          :show-counter="false"
          :show-footer="manager !== null"
          verbose-name="date"
          @selectionChanged="onSelectionChanged($event)"
        ></x-table>
      </div>
    </div>
    <add-activity-date-modal
      :activity="activity"
      :entity="entity"
      :tag="tag"
      :edit-event="editEvent"
      modal-id="bv-edit-agenda-date-modal"
      @done="onEventAdded"
      @updated="onEventUpdated"
    ></add-activity-date-modal>
    <confirm-modal
      name="delete-agenda-date"
      title="Supprimer la date"
      text="Confirmer la suppression de la date"
      :object="editEvent"
      @confirmed="onEventDeleted"
    >
    </confirm-modal>
    <change-activity-date-place-modal
      modal-id="changeActivityDatesModal"
      :selected-dates="selectedItems"
      @done="loadAgendaDates()"
    ></change-activity-date-place-modal>
    <delete-activity-date-place-modal
      modal-id="deleteActivityDatesModal"
      :selected-dates="selectedItems"
      @done="loadAgendaDates()"
    ></delete-activity-date-place-modal>
  </div>
</template>

<script>
import moment from 'moment/moment'
import { mapMutations, mapActions } from 'vuex'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import XTable from '@/components/Controls/Table/Table.vue'
import ChangeActivityDatePlaceModal from '@/components/Agenda/ChangeActivityDatePlaceModal.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeActivityDate } from '@/types/agenda'
import router from '@/router'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import AddActivityDateModal from '@/components/Agenda/AddActivityDateModal.vue'
import DeleteActivityDatePlaceModal from '@/components/Agenda/DeleteActivityDatePlaceModal.vue'

export default {
  name: 'agenda-dates-table',
  components: {
    DeleteActivityDatePlaceModal,
    CounterLabel,
    ChangeActivityDatePlaceModal,
    LoadingGif,
    AddActivityDateModal,
    ConfirmModal,
    XTable,
  },
  mixins: [BackendMixin],
  props: {
    activity: {
      type: Object,
      default: null,
    },
    entity: {
      type: Object,
      default: null,
    },
    tag: {
      type: Object,
      default: null,
    },
    manager: {
      type: Object,
      default: null,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    forceAddEvent: {
      type: Boolean,
      default: false,
    },
    forceChangePlaces: {
      type: Boolean,
      default: false,
    },
    deleteChangePlaces: {
      type: Boolean,
      default: false,
    },
    forceReload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editEvent: null,
      agendaDates: [],
      selectedItems: [],
      loadingName: 'agenda-dates-table',
    }
  },
  computed: {
    canView() {
      return this.hasPerm('agenda.view_activitydate')
    },
    canChange() {
      return this.hasPerm('agenda.change_activitydate')
    },
    canAdd() {
      return this.hasPerm('agenda.add_activitydate')
    },
    canDelete() {
      return this.hasPerm('agenda.delete_activitydate')
    },
    items() {
      return this.agendaDates.map(this.makeItem)
    },
    name() {
      if (this.activity && this.activity.id) {
        return this.activity.name
      } else if (this.entity && this.entity.id) {
        return this.entity.name
      } else if (this.tag && this.tag.id) {
        return this.tag.name
      } else if (this.manager && this.manager.id) {
        return this.manager.individual.firstAndLastName()
      } else {
        return ''
      }
    },
    absenceDates() {
      return this.agendaDates.filter(elt => elt.absence)
    },
    activeDates() {
      return this.agendaDates.filter(elt => !elt.absence)
    },
    hasAbsences() {
      return this.agendaDates.length > 0
    },
    columns() {
      const columns = [
        { selector: true, 'name': 'selector', maxWidth: '20px', },
        {
          name: 'date',
          label: 'Date',
          dateFormat: 'ddd D MMM YYYY',
          isLink: () => {
            return true
          },
          linkUrl: item => {
            const dateVal = moment(item.raw.startDateTime).format('YYYY-MM-DD')
            return router.resolve(
              {
                name: 'agenda-activity-dates',
                query: { date: dateVal, },
              }
            ).href
          },
        }
      ]
      if (this.manager) {
        columns.push({ name: 'label', label: 'Activité', })
      }
      columns.push(
        { name: 'start', label: 'Début', },
        { name: 'end', label: 'Fin', }
      )
      if (this.manager) {
        columns.push({ name: 'duration', label: 'Durée (en h)', colFooterSum: true, })
      }
      columns.push(
        { name: 'place', label: 'Lieu', },
        { name: 'absenceAndComments', label: '', maxWidth: '100px', }
      )
      if (this.canChange) {
        columns.push(
          {
            name: 'edit',
            hideFilter: true,
            label: '',
            title: 'Modifier',
            maxWidth: '30px',
            alignCenter: true,
            onClick: this.onEditEvent,
          }
        )
      }
      if (this.canDelete) {
        columns.push(
          {
            name: 'delete',
            hideFilter: true,
            label: '',
            title: 'Supprimer',
            maxWidth: '30px',
            alignCenter: true,
            onClick: this.onDeleteEvent,
          }
        )
      }
      return columns
    },
  },
  created() {
    this.loadAgendaDates()
  },
  watch: {
    activity() {
      this.loadAgendaDates()
    },
    manager() {
      this.loadAgendaDates()
    },
    entity() {
      this.loadAgendaDates()
    },
    tag() {
      this.loadAgendaDates()
    },
    forceAddEvent() {
      if (this.forceAddEvent) {
        this.$bvModal.show('bv-edit-agenda-date-modal')
        this.$emit('forced')
      }
    },
    forceChangePlaces() {
      if (this.forceChangePlaces) {
        this.$bvModal.show('changeActivityDatesModal')
        this.$emit('forced')
      }
    },
    deleteChangePlaces() {
      if (this.deleteChangePlaces) {
        this.$bvModal.show('deleteActivityDatesModal')
        this.$emit('forced')
      }
    },
    forceReload() {
      if (this.forceReload) {
        this.loadAgendaDates()
        this.$emit('forced')
      }
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    getStyle() {
      if (this.activity) {
        return this.activity.getHeaderStyle()
      } else {
        return {}
      }
    },
    getClass() {
      if (this.activity) {
        return this.activity.disabled ? 'disabled' : ''
      } else {
        return ''
      }
    },
    onEditEvent(item) {
      this.editEvent = item.raw
      this.$bvModal.show('bv-edit-agenda-date-modal')
    },
    onDeleteEvent(item) {
      this.editEvent = item.raw
      this.$bvModal.show('bv-confirm-modal:delete-agenda-date')
    },
    makeItem(elt) {
      const absence = elt.absence ? '<span class="badge badge-warning">absence</span>' : ''
      const comments = elt.comments ? '<div class="badge badge-max badge-light">' + elt.comments + '</div>' : ''
      const conflicts = elt.conflict ? '<span class="badge badge-warning">lieu partagé</span>' : ''
      return {
        id: elt.id,
        date: moment(elt.startDateTime).format('YYYY-MM-DD'),
        start: moment(elt.startDateTime).format('HH:mm'),
        end: moment(elt.endDateTime).format('HH:mm'),
        label: elt.getLabel(),
        duration: elt.getDuration(),
        place: elt.place.name,
        placeId: elt.place.id,
        edit: '<a class="clickable"><i class="fa fa-edit no-print"></i></a>',
        delete: '<a class="clickable"><i class="fa fa-trash no-print"></i></a>',
        raw: elt,
        absenceAndComments: absence + conflicts + comments,
      }
    },
    onEventAdded() {
      // this.agendaDates.push(makeActivityDate(event))
      this.loadAgendaDates()
    },
    onEventUpdated(event) {
      const updated = makeActivityDate(event)
      const index = this.agendaDates.map(elt => elt.id).indexOf(updated.id)
      if (index >= 0) {
        this.agendaDates[index] = updated
        this.agendaDates = [].concat(this.agendaDates)
      }
    },
    async onEventDeleted(event) {
      let url = '/agenda/api/activity-dates/' + event.object.id + '/'
      let backendApi = new BackendApi('delete', url)
      try {
        await backendApi.callApi()
        await this.addSuccess('La date a été supprimée')
        const index = this.agendaDates.map(elt => elt.id).indexOf(event.object.id)
        if (index >= 0) {
          this.agendaDates.splice(index, 1)
          this.agendaDates = [].concat(this.agendaDates)
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onSelectionChanged(event) {
      this.selectedItems = event.items
      this.$emit('selected', { selected: event.items, })
    },
    changePlaces() {
      this.$bvModal.show('changeActivityDatesModal')
    },
    async loadAgendaDates() {
      this.startLoading(this.loadingName)
      try {
        let url = ''
        if (this.activity) {
          url = '/agenda/api/activity-dates/?activity=' + this.activity.id
        } else if (this.entity) {
          url = '/agenda/api/entity-dates/' + this.entity.id + '/'
        } else if (this.tag) {
          url = '/agenda/api/tag-dates/' + this.tag.id + '/'
        } else if (this.manager) {
          url = '/agenda/api/manager-dates/' + this.manager.individual.id + '/'
        }
        if (url) {
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          this.agendaDates = resp.data.map(elt => makeActivityDate(elt))
        } else {
          this.agendaDates = []
        }
        this.$emit('loaded', { dates: this.agendaDates, })
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
