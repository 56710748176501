<template>
  <span class="delete-activity-date-place-modal" v-if="canDelete">
    <b-modal
      dialog-class="modal-md"
      :id="modalId"
      @ok.prevent="onSave"
      ok-variant="danger"
      cancel-title="Annuler"
      ok-title="Supprimer"
      :ok-disabled="!isValid()"
      @shown="init()"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fas fa-trash"></i>&nbsp;
          <span>Supprimer les dates</span>
        </b>
      </template>
      <div v-if="errors.length" class="errors-message" v-html="errorsMessage">
      </div>
      <b-row>
        <b-col cols="12">
          <b>Attention les les dates suivantes seront supprimées</b>
          <ul>
            <li v-for="eventDate of selectedDates" :key="eventDate.id">
              {{ eventDate.date | dateToString('LL') }} {{ eventDate.start }}
              {{ eventDate.place }}
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makePlace } from '@/types/base'
import { dateToString } from '@/filters/texts'

export default {
  name: 'delete-activity-date-place-modal',
  components: { },
  mixins: [BackendMixin],
  props: {
    modalId: String,
    selectedDates: Array,
  },
  data() {
    return {
      errors: [],
    }
  },
  computed: {
    canDelete() {
      return this.hasPerm('agenda.delete_activitydate')
    },
    errorsMessage() {
      return this.errors.join('<br />')
    },
  },
  watch: {
    selectedDates: function() {
      this.init()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async init() {
    },
    async onSave() {
      this.errors = []
      for (const eventDate of this.selectedDates) {
        let url = '/agenda/api/activity-dates/' + eventDate.id + '/'
        let backendApi = new BackendApi('delete', url)
        try {
          const resp = await backendApi.callApi({})
        } catch (err) {
          this.errors.push(dateToString(eventDate.date) + ' ' + this.getErrorText(err))
        }
      }
      if (this.errors.length === 0) {
        this.$bvModal.hide(this.modalId)
        this.$emit('done')
      }
    },
    isValid() {
      return true
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.errors-message {
  background: #f0ad4e;
  padding: 4px 10px;
  font-weight: bold;
}
</style>
