import Vue from 'vue'

export const AgendaMixin = Vue.extend({
  created: () => {},
  data() {
    return {
      forceAddEvent: false,
      forceChangePlace: false,
      deleteChangePlaces: false,
      forceReload: false,
      isForcePlaceActive: false,
    }
  },
  computed: {},
  watch: {},
  methods: {
    onAddEvent() {
      this.forceAddEvent = true
    },
    onChangePlace() {
      if (this.isForcePlaceActive) {
        this.forceChangePlace = true
      }
    },
    onDeletePlaces() {
      if (this.isForcePlaceActive) {
        this.deleteChangePlaces = true
      }
    },
    onForceDone() {
      this.forceAddEvent = false
      this.forceChangePlace = false
      this.deleteChangePlaces = false
      this.forceReload = false
    },
    onSelectionDone(event: any) {
      this.isForcePlaceActive = event.selected.length > 0
    },
  },
})
