<template>
  <span class="change-activity-date-place-modal" v-if="canChange">
    <b-modal
      dialog-class="modal-md"
      :id="modalId"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Enregistrer"
      :ok-disabled="!isValid()"
      @shown="init()"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fas fa-location-dot"></i>&nbsp;
          <span>Modifier un lieu</span>
        </b>
      </template>
      <div v-if="errors.length" class="errors-message" v-html="errorsMessage">
      </div>
      <b-row>
        <b-col cols="12">
          Le lieu pour les dates suivantes sera modifié avec
          <ul class="small-text">
            <li
              v-for="eventDate of selectedDates"
              :key="eventDate.id"
              :class="{ disabled: selectedPlace && (eventDate.placeId === selectedPlace.id) }"
            >
              {{ eventDate.date | dateToString('LL') }} {{ eventDate.start }}
              {{ eventDate.place }}
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label-for="place"
            label="Lieux"
          >
            <b-select
              v-model="selectedPlace"
              name="selectedPlace"
              :disabled="allPlaces.length <= 1"
            >
              <b-select-option v-for="place of allPlaces" :key="place.id" :value="place">
                {{ place.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="errors.length">
        <b-col>
          <b-form-group
            label-for="ignoreConflict"
            label="Forçage"
            description="si coché, la séance peut-être crée même si la salle est déjà utilisée à ce moment"
          >
            <b-form-checkbox id="ignoreIfExist" v-model="ignoreConflict">
              Ignorer les conflits
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makePlace } from '@/types/base'
import { dateToString } from '@/filters/texts'

export default {
  name: 'change-activity-date-place-modal',
  components: { },
  mixins: [BackendMixin],
  props: {
    modalId: String,
    selectedDates: Array,
  },
  data() {
    return {
      allPlaces: [],
      selectedPlace: null,
      errors: [],
      ignoreConflict: false,
      inProgress: false,
    }
  },
  computed: {
    canChange() {
      return this.hasPerm('agenda.change_activitydate')
    },
    errorsMessage() {
      return this.errors.join('<br />')
    },
    listOfDates() {
      if (this.selectedPlace) {
        return this.selectedDates.filter(
          elt => elt.placeId !== this.selectedPlace.id
        )
      } else {
        return this.selectedDates
      }
    },
  },
  watch: {
    selectedDates: function() {
      this.init()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async init() {
      this.errors = []
      this.ignoreConflict = false
      if (!this.places) {
        await this.loadAllPlaces()
      } else {
        this.allPlaces = this.places
      }
    },
    async onSave() {
      this.errors = []
      this.inProgress = true
      for (const eventDate of this.listOfDates) {
        let url = '/agenda/api/activity-dates/' + eventDate.id + '/change_date/'
        const data = {
          place: this.selectedPlace.id,
        }
        if (this.ignoreConflict) {
          data['ignore_conflict'] = true
        }
        let backendApi = new BackendApi('patch', url)
        try {
          const resp = await backendApi.callApi(data)
          eventDate.place = resp.data.place.name
          eventDate.placeId = resp.data.place.id
        } catch (err) {
          this.errors.push(dateToString(eventDate.date) + ' ' + this.getErrorText(err))
        }
      }
      this.inProgress = false
      if (this.errors.length === 0) {
        this.$bvModal.hide(this.modalId)
        this.$emit('done')
      }
    },
    async loadAllPlaces() {
      try {
        let url = '/api/home/places/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.allPlaces = resp.data.map(elt => makePlace(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    isValid() {
      return (!this.inProgress) && this.selectedPlace && (this.listOfDates.length > 0)
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.errors-message {
  background: #f0ad4e;
  padding: 4px 10px;
  font-weight: bold;
}
li.disabled {
  color: #ccc;
}
</style>
